import request from './request'
import { QueryDateSlotDto, QueryPageDto } from './dto'
// beginCreateTime  endCreateTime  serveCompanyName
interface InsureDeductionDto extends QueryDateSlotDto, QueryPageDto {
    searchText: string
    insureCompanyType?:string
}
export interface insureDeductionDetailListDto {
    amount: number
    comboName: string
    beginCreateTime?: string
    endCreateTime?: string
    idCardNo?: string
    phone?: string
    userName?: string
    createTime: string
    id: number | null
    policyPeopleCount: number
    serveCompanyName: string
    insureRate:number
}
export interface InsureProjectDto {
    amount: number
    beginCreateTime: string
    comboName: string
    endCreateTime: string
    insurePeople: number
    serveCompanyName: string
}
interface InsureDeductionTopInfoDto {
    insureProject?: InsureProjectDto
    totalCount: number
    insureCount: {
        amount: number
        insureProjectCount: number
        policyPeopleCount: number
    }
    list: insureDeductionDetailListDto[]
}

// 保险扣费记录
export function insureDeduction (
    params: InsureDeductionDto
): Promise<InsureDeductionTopInfoDto> {
    return request.get('/rest/insure/insure-info/list', {
        params
    })
}

interface insureDeductionDetailDto extends QueryPageDto {
    id: string | string[]
}

// 保险扣费记录详情
export function insureDeductionDetail (
    params: insureDeductionDetailDto
): Promise<InsureDeductionTopInfoDto> {
    return request.get('/rest/insure/insure-info/list-info-project', {
        params
    })
}

// 获取投保企业列表
export interface InsuranceCompanyDto {
    id: number
    companyName: string
    contacts: string
    phone: string
    serveCompanyName: string
}
export function getInsureCompanyList (): Promise<InsuranceCompanyDto[]> {
    return request.get('/rest/insure/insure-info/server-company-open-wesure')
}

// 获取投保方案列表
export interface PlanDto {
    id: number
    industryPlanName: string
    wesurePlanCode: string
    wesurePlanName: string
    price: number
}
export function getInsurePlanList (params: {
    serveCompanyId: number
    productType:any
}): Promise<PlanDto[]> {
    return request.get('/rest/insure/insure-info/plan-list', {
        params
    })
}
export interface UserInfoDTOList {
    serveCompanyId: number | string,
    gscUserInfoDTOList:{
        userName:string,
        idCard:string
    }[]
}
export function batchInsure (data: {
    industryPlanId: string
    serveCompanyId: number
    wesureType: string
    wesureTypeName: string
    userList: {
        userName: string
        idCard: string
    }[]
    projectNum: number
}): Promise<any> {
    return request.post('/rest/insure/insure-info/insert-gsc-wesure', data)
}

export function getInsured (data: UserInfoDTOList): Promise<any> {
    return request.post('/rest/insure/insure-info/check-employee-wesure', data)
}

export function insureInfoProjectNum (): Promise<{ projectNum: number }> {
    return request.get('/rest/insure/insure-info/project-num')
}

interface InsureDispatchListDto extends QueryPageDto {
    serveCompanyName: string
}
//  /rest/insure/insure-info/list-gsc 日日保派遣单位 {projectNum:number}
export function insureDispatchList (data: InsureDispatchListDto): Promise<any> {
    return request.get('/rest/insure/insure-info/list-gsc', {
        params: data
    })
}

// rest/insure/insure-info/delete-wesure-server-company 日日保派遣单位删除
export function insureDispatchDelete (data: { id: string }): Promise<any> {
    return request.put('/rest/insure/insure-info/delete-wesure-server-company', data)
}

// 提交被派遣单位 /rest/insure/insure-info/add-wesure-server-company-gsc
export function addDispatch (data: {
    serveCompanyId: number
    params: { photos: { fileSize: string, fileType: string, fileUrl: string }[] }
    remark: string
    applySource: number
}): Promise<void> {
    return request.post('/rest/insure/insure-info/add-wesure-server-company-gsc', data)
}

// 工对工 日日保 充值
export function insuranceRecharge (data: any): Promise<any> {
    return request.post('/rest/company/wesure-recharge', data)
}

// 团意保 充值信息接口
export function insureChargeAccount (): Promise<any> {
    return request.get('/rest/company/tyx-charge-account')
}

// 查询日日保在保人员接口
export function queryInsureRecord (data:any): Promise<any> {
    return request.get('/wesure/wesure-insure-record/query-wesure-insure-record', {
        params: data
    })
}

// 查询日日保 方案列表
export function queryPlans (data:any): Promise<any> {
    return request.get('/wesure/wesure-industry-plan/query-plans', {
        params: data
    })
}

// 查询日日保充值记录
export function billRecord (data:QueryPageDto): Promise<any> {
    return request.get('/rest/company/wesure-recharge-list', {
        params: data
    })
}

// 获取日保 首页余额数据
export function insureBalance (data:any): Promise<any> {
    return request.get('/wesure/wesure-insure-record/wesure-data-port', {
        params: data
    })
}

// 查询团意保 充值记录
export function billTeamRecord (data:any): Promise<any> {
    return request.get('/rest/company/tyx-recharge-list', {
        params: data
    })
}

// 创建日日保在线报案
export function createInsureCase (data:any): Promise<any> {
    return request.post('/wesure/wesure-report-case/report-case', data)
}

// 上传日日保报案材料
export function uploadInsureCaseMaterial (data: any): Promise<any> {
    return request.post('/rest/period-insur/insur-period-report-case/upload', data)
}

// 获取日日保 或 团意保投保人员信息 列表
export function insureUserGuarantee (data:any): Promise<any> {
    return request.get('/wesure/wesure-report-case/out-danger-user-info', {
        params: data
    })
}

// 获取日保 报案列表信息
export function insureCaseList (data:any): Promise<any> {
    return request.get('/wesure/wesure-report-case/out-danger-info', {
        params: data
    })
}

// 获取日保 报案详情/wesure/wesure-report-case/{id}
export function insureCaseDetail (data:any): Promise<any> {
    return request.get('/wesure/wesure-report-case/' + data)
}

// 检测公司是否可以批量投保 日日保
export function checkStatusWesure (): Promise<any> {
    return request.get('/insur-com-company/insur-com-company/check-status-wesure')
}

// 获取新团队成员列表 /rest/company/get-user-info
